import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { SelectAssetSearchWidget, SelectAssetSearchWidgetProps } from '@/core/SelectAssetSearchWidget.molecule';

interface SelectAssetModalIF extends SelectAssetSearchWidgetProps {
  onClose: () => void;
  showHeaderCloseButton?: boolean;
  testId?: string;
  header: JSX.Element | HTMLElement;
  /** HTML that should appear above search widget, but below title **/
  body: JSX.Element | HTMLElement | null;
  /** HTML that should appear below the search widget **/
  footer: JSX.Element | HTMLElement | null;
}

export const SelectAssetModal: React.FunctionComponent<SelectAssetModalIF> = ({
  onClose,
  header,
  body,
  footer,
  showHeaderCloseButton = true,
  onSelect,
  iconPartialTooltipKey,
  assetId,
  validateAsset,
  scopeIds,
  excludeGloballyScoped = false,
  testId = 'selectAssetModal',
  modalId = testId,
}) => {
  return (
    <Modal
      show={true}
      onHide={onClose}
      animation={false}
      size="sm"
      scrollable={true}
      contentClassName="scrollableSearchModal"
      data-testid={testId}>
      <>
        <Modal.Header closeButton={showHeaderCloseButton}>{header as ReactNode}</Modal.Header>
        <Modal.Body>
          <>
            {body}
            <SelectAssetSearchWidget
              onSelect={onSelect}
              iconPartialTooltipKey={iconPartialTooltipKey}
              assetId={assetId}
              validateAsset={validateAsset}
              scopeIds={scopeIds}
              excludeGloballyScoped={excludeGloballyScoped}
              modalId={modalId}
            />
          </>
        </Modal.Body>
        {footer}
      </>
    </Modal>
  );
};
