import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/core/Icon.atom';

interface TabTitleProps {
  title: string;
  icon?: string;
}

export const TabTitle: React.FunctionComponent<TabTitleProps> = ({ title, icon }) => {
  const { t } = useTranslation();

  return (
    <>
      {icon && <Icon icon={icon} type="theme" extraClassNames="tab-icon pr3" />}
      {t(title)}
    </>
  );
};
