import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/core/Icon.atom';

/**
 * Small tooltip icon with custom translated text
 */
interface TooltipButtonProps {
  tooltipText: string;
  tooltipKBLink: string;
}

export const TooltipButton: React.FunctionComponent<TooltipButtonProps> = ({ tooltipKBLink, tooltipText }) => {
  const { t } = useTranslation();

  return (
    <a href={tooltipKBLink} target="_blank" className="ml5">
      <Icon
        icon="fa-question-circle"
        tooltip={tooltipText}
        tooltipPlacement="right"
        extraClassNames="text-interactive"
      />
    </a>
  );
};
