// @ts-strict-ignore
import _ from 'lodash';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useEffect } from 'react';
import { getTrendStores } from '@/trend/trendDataHelper.utilities';

/**
 * Listens to a property for all of the trend stores in TREND_STORES and calls a function on change
 *
 * @example:
 * useAllTrendStoresProperty('items', setItemColor);
 *
 * @param property - the property to listen to for all of the trend stores
 * @param onPropertyChange - the function to call when one of the stores has that property change
 */
export function useAllTrendStoresProperty(property, onPropertyChange: Function) {
  const storeProperties = _.map(getTrendStores(), (store) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useFluxPath(store, () => store[property]);
  });

  useEffect(() => {
    onPropertyChange();
  }, storeProperties);
}
