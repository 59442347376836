import _ from 'lodash';
import React from 'react';
import { SearchWidget } from '@/search/SearchWidget.organism';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { isAsset } from '@/utilities/utilities';
import { onEventPreventPropagation } from '@/core/onEnterKeypress.util';
import { Icon } from '@/core/Icon.atom';
import { AnyProperty } from '@/utilities.types';
import { ItemPreviewV1 } from '@/sdk';

export interface SelectAssetSearchWidgetProps {
  onSelect: (item: SelectedAsset) => void;
  iconPartialTooltipKey: string;
  assetId?: string;
  validateAsset: (item: SelectedAsset) => Promise<boolean>;
  scopeIds: string[];
  excludeGloballyScoped?: boolean;
  modalId?: string;
}

export type SelectedAsset = { id: string; name: string; ancestors?: ItemPreviewV1[] };

const SEARCH_TYPES = [SeeqNames.Types.Asset, SeeqNames.Types.AssetSelection];

export const SelectAssetSearchWidget: React.FunctionComponent<SelectAssetSearchWidgetProps> = ({
  onSelect,
  iconPartialTooltipKey,
  assetId,
  validateAsset,
  scopeIds,
  excludeGloballyScoped = false,
  modalId,
}) => {
  const searchResultIcons = (item: AnyProperty): Promise<JSX.Element | void> => {
    if (!isAsset(item)) {
      return Promise.resolve();
    }

    return validateAsset(item as SelectedAsset).then((isValid) => {
      const isSelected = assetId ? item.id === assetId : false;
      return isValid ? (
        <span onClick={onEventPreventPropagation(() => onSelect(item as SelectedAsset))}>
          <Icon
            testId="assetSelect"
            extraClassNames="searchBtn"
            tooltip={`${iconPartialTooltipKey}.ASSET_TOOLTIP`}
            large={true}
            icon={isSelected ? 'fa-check-circle' : 'fa-circle-o'}
          />
        </span>
      ) : (
        <span onClick={onEventPreventPropagation(_.noop)}>
          <Icon
            testId="assetSelectInvalid"
            extraClassNames="disabled mr3 p3"
            type="color"
            color="grey"
            tooltip={`${iconPartialTooltipKey}.ASSET_TOOLTIP_INVALID`}
            large={true}
            icon="fa-circle-o"
          />
        </span>
      );
    });
  };

  return (
    <SearchWidget
      isSelectingAsset={true}
      iconClasses="sq-fairly-dark-gray"
      pane="modal"
      searchTypes={SEARCH_TYPES}
      searchResultIcons={searchResultIcons}
      allowAssetReplacement={false}
      showOnlyResults={true}
      scopeIds={scopeIds}
      excludeGloballyScoped={excludeGloballyScoped}
      modalId={modalId}
    />
  );
};
