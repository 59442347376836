// @ts-strict-ignore
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { useMemoCompare } from '@/core/hooks/useMemoCompare.hook';
import { ITEM_CHILDREN_TYPES, ITEM_DATA_STATUS, ITEM_TYPES, PREVIEW_ID } from '@/trendData/trendData.constants';
import {
  sqTrendCapsuleSetStore,
  sqTrendScalarStore,
  sqTrendCapsuleStore,
  sqTrendMetricStore,
  sqTrendSeriesStore,
  sqTrendTableStore,
} from '@/core/core.stores';

type UseAllTrendStoreItemsProps = {
  includeSignalPreview?: boolean;
  excludeEditingCondition?: boolean;
  workingSelection?: boolean;
  excludeDataStatus?: ITEM_DATA_STATUS[];
  itemTypes?: ITEM_TYPES[];
  itemChildrenTypes?: ITEM_CHILDREN_TYPES[];
  transformer?: (items) => any;
  memoComparison?: (previous, next) => boolean;
};

/**
 * Query the TREND_STORES for a list of items. By default this will return only the items in the details pane.
 *
 * @example:
 * const items = useAllTrendStoreItems({ transformer: items => sortAndDecorateItems({ sort, items, ...rest }) });
 *
 * @param includeSignalPreview - include the preview signal (if available).
 * @param excludeEditingCondition - include the condition being edited (if there is one).
 * @param workingSelection - if true only selected items (or all if none are selected) will be returned
 * @param excludeDataStatus - items without these dataStatus will be returned (list of ITEM_DATA_STATUS)
 * @param itemTypes - only items with these types will be returned (list of ITEM_TYPES)
 * @param itemChildrenTypes - child types to include (list of ITEM_CHILDREN_TYPES)
 * @param transformer - function to call on _.thru for the items, this can be used to decorate or modify the items
 * so that expensive operations will only be performed when the items change. This function needs to return the
 * modified items.
 * @param memoComparison - comparison function to determine if two lists of items are equivalent for the purposes of
 * re-rendering
 * @param dependencies - array of dependencies to add to the useEffect that determine when the items need to be updated
 * @return Object[] list of items
 */
export function useAllTrendStoreItems(
  {
    includeSignalPreview = false,
    excludeEditingCondition = false,
    workingSelection = false,
    excludeDataStatus = [],
    itemTypes = [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE, ITEM_TYPES.METRIC],
    itemChildrenTypes = [],
    transformer = _.identity,
    memoComparison = _.isEqual,
  }: UseAllTrendStoreItemsProps = {},
  dependencies = [],
) {
  const { items: seriesItems, itemsAndPreview: seriesItemsAndPreview } = useFlux(sqTrendSeriesStore);
  const { items: scalarItems } = useFlux(sqTrendScalarStore);
  const { items: capsuleItems } = useFlux(sqTrendCapsuleStore);
  const { items: capsuleSetItems } = useFlux(sqTrendCapsuleSetStore);
  const { items: tableItems } = useFlux(sqTrendTableStore);
  const { items: metricItems } = useFlux(sqTrendMetricStore);

  // The useMemoCompare hook is used here to prevent constant re-renders. Because these items are coming from the
  // stores, React constantly thinks the items are new so it will re-render even when the items haven't changed.
  const series = useMemoCompare(seriesItems, memoComparison);
  const seriesAndPreview = useMemoCompare(seriesItemsAndPreview, memoComparison);
  const scalars = useMemoCompare(scalarItems, memoComparison);
  const capsules = useMemoCompare(capsuleItems, memoComparison);
  const capsuleSets = useMemoCompare(capsuleSetItems, memoComparison);
  const tables = useMemoCompare(tableItems, memoComparison);
  const metrics = useMemoCompare(metricItems, memoComparison);

  const getItems = () => {
    const allItems = _.concat(
      includeSignalPreview ? seriesAndPreview : series,
      scalars,
      capsules,
      capsuleSets,
      tables,
      metrics,
    );

    return _.chain(allItems)
      .filter((item) => _.includes(itemTypes, item.itemType))
      .reject((item) => _.includes(excludeDataStatus, item.dataStatus))
      .filter((item) => !item.isChildOf || _.includes(itemChildrenTypes, item.childType))
      .reject((item) => excludeEditingCondition && item.id === sqTrendCapsuleStore.editingId)
      .thru((items) =>
        workingSelection && _.some(items, 'selected')
          ? _.filter(items, (item) => item.selected || item.id === PREVIEW_ID)
          : items,
      )
      .thru(transformer)
      .value();
  };

  const [items, setItems] = useState(transformer === _.identity ? getItems() : []);

  useEffect(() => {
    setItems(getItems());
  }, [series, seriesAndPreview, scalars, capsules, capsuleSets, tables, metrics, ...dependencies]);

  return items;
}
