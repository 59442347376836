// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { HoverTooltip } from './HoverTooltip.atom';

interface UnitOfMeasureProps {
  unitOfMeasure: { value: any; isRecognized: boolean };
  isStandalone?: boolean;
  /** set this to true if the icon is inside a <SingletonTooltip> element. In this case the tooltip and placement
   * information will be passed on as data attributes and a singleton tooltip element will be reused for this icon.
   * This is recommended for improved performance when there are many icons with tooltips. */
  hasExternalTooltipHandler?: boolean;
}

/**
 * Renders the unit of measure if it is available. If the unit is rendered "standalone" no wrapping parenthesis
 * are included. If an unknown unit of measure is found it is displayed in italics and slightly transparent,
 * opaque on hover.
 */
export const UnitOfMeasure: React.FunctionComponent<UnitOfMeasureProps> = (props) => {
  const { unitOfMeasure, isStandalone, hasExternalTooltipHandler } = props;
  if (_.isEmpty(unitOfMeasure) || !unitOfMeasure.value) {
    return null;
  }

  const style = classNames('inline', {
    'text-italic': !unitOfMeasure.isRecognized,
    'text-opaque-onhover': !unitOfMeasure.isRecognized,
  });

  const tooltip = unitOfMeasure.isRecognized ? null : 'UNRECOGNIZED_UNIT';

  const unitOfMeasureTag = (
    <span
      className={style}
      data-testid="unitOfMeasure"
      data-tooltip-text={hasExternalTooltipHandler ? tooltip : null}
      data-tooltip-placement={'top'}>
      {!isStandalone && '('}
      {unitOfMeasure.value}
      {!isStandalone && ')'}
    </span>
  );

  return !hasExternalTooltipHandler ? (
    <HoverTooltip text={tooltip} placement={'top'}>
      {unitOfMeasureTag}
    </HoverTooltip>
  ) : (
    unitOfMeasureTag
  );
};
