import React from 'react';
import { useTranslation } from 'react-i18next';
import { IdentityOption, SelectIdentity } from '@/core/SelectIdentity.molecule';
import _ from 'lodash';
import { TableColumn } from '@/core/Table.atom';

export interface UserFilterTableHeaderProps {
  column: TableColumn;
  userIdentities: IdentityOption[];
  onUserIdentitiesChange: (userIdentities: IdentityOption[]) => void;
}

export const UserFilterTableHeader: React.FunctionComponent<UserFilterTableHeaderProps> = ({
  column,
  onUserIdentitiesChange,
  userIdentities,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flexRowContainer">
      {column.header && (
        <div data-testid={`${column.accessor}_header`} className="cursorNotAllowed">
          {t(column.header)}
        </div>
      )}
      <div data-testid={`${column.accessor}_headerInputWrapper`} className="multiSelectTwoLineScrollOnly">
        <SelectIdentity
          idForLabel="identityName"
          onIdentityChange={onUserIdentitiesChange}
          setIdentity={_.noop}
          identity={userIdentities}
          startEditable={true}
          placeholder="USERS"
          multiple={true}
        />
      </div>
    </div>
  );
};
