import React, { useEffect, useRef } from 'react';

/**
 * A hook watch for any resize event for an element
 * It uses the ResizeObserver functions that work in most browsers with the exception of IE
 *
 * @param resizeConfig - the configuration object
 * @param element - the DOM element to check for resize events
 * @param callback - the callback function to be used
 * @param callOnLoad - true if the callback should be called on load as well
 * @return callback for the cleanup
 */
interface ResizeConfigParams {
  element?: HTMLElement;
  elementRef?: React.RefObject<HTMLElement>;
  callback: (o: { oldWidth: number; oldHeight: number; newWidth: number; newHeight: number }) => void;
  callOnLoad: boolean;
}

export function useResizeWatcher(
  { element, elementRef, callback, callOnLoad }: ResizeConfigParams,
  dependencies: React.DependencyList = [],
) {
  const width = useRef(-1);
  const height = useRef(-1);
  const resizeObserver = useRef<ResizeObserver | undefined>(undefined);
  const isLoaded = useRef(false);

  useEffect(() => {
    const currentElement = elementRef ? elementRef.current : element;

    const compareAndCallback = (w: number, h: number, forceCallback = false) => {
      if (forceCallback || w !== width.current || h !== height.current) {
        callback({
          oldWidth: width.current,
          oldHeight: height.current,
          newWidth: w,
          newHeight: h,
        });
        width.current = w;
        height.current = h;
      }
    };

    if (currentElement) {
      if (resizeObserver.current) {
        resizeObserver.current?.disconnect();
      }

      resizeObserver.current = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect;
        compareAndCallback(width, height);
      });
      resizeObserver.current.observe(currentElement);

      if (callOnLoad && !isLoaded.current) {
        compareAndCallback(currentElement.offsetWidth, currentElement.offsetHeight, true);
        isLoaded.current = true;
      }
    }
  }, dependencies);

  useEffect(
    () => () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
        resizeObserver.current = undefined;
      }
    },
    [],
  );
}
