type StopUpdateFunction = (stopUpdate: boolean) => void;
const listenersList: Map<string, StopUpdateFunction> = new Map();
let observer: IntersectionObserver, isObserverActive: boolean;

/**
 * Setup the intersection observer
 * @param element Element to use as root
 * @param isActive enabled or disable the observer callback
 */
function setupIntersectionObserver(element: HTMLElement, isActive = true): void {
  isObserverActive = isActive;
  /**
   * The rootMargin is used here to "enlarge" the size of the element with 1000px on top and 1000px on bottom
   * so that we will actually consider the elements visible 1000px before they reach the top or bottom of the
   * screen. The threshold is used so the Observer notifies us when elements overlap 100% or 0%. This will
   * allow us to know when an element will barely start to overlap our rootElement or is completely not
   * overlapping our rootElement
   */
  let options = {
    root: element,
    rootMargin: '1000px 0px 1000px 0px',
    threshold: [0, 1.0],
  };
  observer = new IntersectionObserver(observerCallback, options);
}

function observerCallback(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
  if (!isObserverActive || !observer) {
    return;
  }
  entries.forEach((entry: IntersectionObserverEntry) => {
    const targetId = entry.target.getAttribute('id');
    if (targetId && listenersList.has(targetId)) {
      const callback = listenersList.get(targetId);
      if (callback) {
        callback(!entry.isIntersecting);
      }
    }
  });
}

function addObserverTarget(target: HTMLElement, setStopUpdate: StopUpdateFunction): void {
  if (!observer || !target) {
    return;
  }
  observer.observe(target);
  const targetId = target.getAttribute('id');
  if (targetId) {
    listenersList.set(targetId, setStopUpdate);
  }
}

function removeObserverTarget(target: HTMLElement): void {
  if (!observer || !target) {
    return;
  }
  observer.unobserve(target);
  const targetId = target.getAttribute('id');
  if (targetId) {
    listenersList.delete(targetId);
  }
}

export { setupIntersectionObserver, addObserverTarget, removeObserverTarget };
