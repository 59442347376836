import { Placement } from 'react-bootstrap/esm/Overlay';

/**
 * Calculate the correct position of any tooltip for a specific element
 * based on the screen positioning (quadrant)
 *
 * @param domElement Target HTMLElement that needs to be checked
 * @returns Placement
 */
export const calculateOptimalTooltipPosition: (domElement: HTMLElement, popoverDomElement: HTMLElement) => Placement = (
  domElement,
  popoverDomElement,
) => {
  let leftOrRight: Placement = 'right';
  let startOrEnd = 'start';
  if (!domElement || !popoverDomElement || !window || !window.innerWidth) return leftOrRight;
  const domRect = domElement.getBoundingClientRect();
  const popoverDomRect = popoverDomElement.getBoundingClientRect();

  if (domRect.x + domRect.width + popoverDomRect.width > window.innerWidth) {
    leftOrRight = 'left';
  }

  if (domRect.y + domRect.height + popoverDomRect.height > window.innerHeight) {
    startOrEnd = 'end';
  }

  return `${leftOrRight}-${startOrEnd}` as Placement;
};
