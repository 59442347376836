import React, { useState } from 'react';
import { Checkbox } from '@/core/Checkbox.atom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/core/TextButton.atom';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import TimeZoneSelector from '@/core/TimeZoneSelector.molecule';
import { setUserPreferencesDisplay } from '@/workbench/workbench.actions';
import _ from 'lodash';
import { sqWorksheetStore } from '@/core/core.stores';
import { setTimezone as worksheetActionsSetTimezone } from '@/worksheet/worksheet.actions';

interface TimezoneModalProps {
  close: () => void;
  userFallback: boolean;
  onChange?: () => void;
}

export const TimezoneModal: React.FunctionComponent<TimezoneModalProps> = ({
  close,
  onChange = _.noop,
  userFallback,
}) => {
  const { t } = useTranslation();

  const [fixed, setFixed] = useState(sqWorksheetStore.timezoneFixed);
  const [timezone, setTimezone] = useState(sqWorksheetStore.timezone);

  const openUserPreferences = () => {
    setUserPreferencesDisplay(true);
    close();
  };

  const save = () => {
    if (fixed !== sqWorksheetStore.timezoneFixed || timezone !== sqWorksheetStore.timezone) {
      worksheetActionsSetTimezone(fixed ? timezone : undefined);
      onChange();
    }
    close();
  };

  return (
    <Modal show={true} onHide={close} animation={false} size="sm">
      <Modal.Header closeButton={true} onHide={close}>
        <Modal.Title>{t('TIME_ZONE_MODAL.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb15">
          {t(userFallback ? 'TIME_ZONE_MODAL.DESCRIPTION_WORKSHEET' : 'TIME_ZONE_MODAL.DESCRIPTION_TOPIC')}
        </div>
        <div className="flexRowContainer mb0">
          <div id="timezoneLocal" className="flexColumnContainer flexSpaceBetween flexAlignCenter">
            <Checkbox
              type="radio"
              id="setTimeZoneFlex"
              isChecked={!fixed}
              onClick={() => setFixed(false)}
              tooltip={userFallback ? 'TIME_ZONE_MODAL.USER_TOOLTIP' : 'TIME_ZONE_MODAL.NOT_CONFIGURED_TOOLTIP'}
              label={userFallback ? 'TIME_ZONE_MODAL.USER' : 'TIME_ZONE_MODAL.NOT_CONFIGURED'}
            />
            {userFallback && (
              <HoverTooltip text="TIME_ZONE_MODAL.EDIT_PREFERENCES_TOOLTIP" placement="right">
                <a
                  type="button"
                  className="ml5 mr8 cursorPointer font-size-smaller text-italic sq-text-primary text-underline"
                  onClick={openUserPreferences}>
                  {t('TIME_ZONE_MODAL.EDIT_PREFERENCES')}
                </a>
              </HoverTooltip>
            )}
          </div>
          <div id="timezoneWorksheet" className="flexRowContainer mt2" onClick={() => setFixed(true)}>
            <Checkbox
              type="radio"
              id="setTimeZoneFixed"
              isChecked={fixed}
              onClick={() => setFixed(true)}
              tooltip={userFallback ? 'TIME_ZONE_MODAL.FIXED_TOOLTIP' : 'TIME_ZONE_MODAL.FIXED_TOPIC_TOOLTIP'}
              label="TIME_ZONE_MODAL.FIXED"
            />
            <HoverTooltip
              placement="right"
              text={userFallback ? 'TIME_ZONE_MODAL.FIXED_TOOLTIP' : 'TIME_ZONE_MODAL.FIXED_TOPIC_TOOLTIP'}>
              <div className="ml25">
                <TimeZoneSelector timezone={timezone} onSelect={setTimezone} />
              </div>
            </HoverTooltip>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flexFill" />
        <TextButton extraClassNames="mr15 min-width-60" label="CANCEL" onClick={close} testId="cancelButton" />
        <TextButton extraClassNames="min-width-60" label="SAVE" onClick={save} testId="saveButton" variant="theme" />
      </Modal.Footer>
    </Modal>
  );
};
