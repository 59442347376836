import React from 'react';
import { Icon } from '@/core/Icon.atom';
import classNames from 'classnames';

interface TableLoadingIndicatorProps {
  testId?: string;
  extraClassNames?: string;
}

export const TableLoadingIndicator: React.FunctionComponent<TableLoadingIndicatorProps> = ({
  testId,
  extraClassNames,
}) => {
  return (
    <div data-testid={testId} className="flexColumnContainer flexCenter pt50 pb50">
      <Icon icon="fa-spinner" extraClassNames={classNames('fa-pulse fa-spin fa-5x', extraClassNames)} />
    </div>
  );
};
